import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settingsStore", {

  state: () => ({

    http: useNuxtApp().$http,

    authStore: useAuthStore(),

    settings: {},

  }),

  getters: {
    setting: (state) => (key) => {
      return state.settings[key]
    }
  },

  actions: {

    // settings is a object
    setSettings(settings) {

      // loop through object
      for (const [key, value] of Object.entries(settings)) {
        this.settings[key] = value
      }

    },

    setSetting(key, value) {
      this.settings[key] = value
    },

    getSetting(key) {
      return this.settings[key]
    },

    async storeSettings(form) {

      let response = await this.http.store('company/settings', form)

      this.setSettings(form)
    },

  }
})




